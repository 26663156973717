

.key_text{
    text-align: right;

}


.card_key_div{
    border-color: red;
    width: 18rem;
}
.sincerity_div{
    background-image: url('../../images/key_img/key_background.png');
    background-repeat: no-repeat;
    object-fit: cover;
    min-height: 230px;
    background-size:100% ;
    text-align: center;
    padding-top: 20px;
    margin-bottom: 25px;
    border-radius: inherit;
}
.key_card{
    border-radius: 0 100px 0 0;
    text-align: center;
    cursor: pointer;
    background-color: var(--color-blackest);

}
.key_cardBody{
    padding: 15px 10px 25px 10px;
    // background-color: var(--color-blackest);

}
.flex-bar-right{
    display: flex;
    justify-content: right;
}
.black-bar{
    width: 128px;
    height: 11px;
    background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98%/200% 100%;
    margin: 1% 0;
}
.key_col1{
    margin-top: -75px;
}
.key_col2{
    margin-top: 35px;
}
.key_col3{
    margin-top: 120px;
}

@media only screen and (max-width: 992px) {
.key_col1{
    margin-top: 0px !important;
    max-width: 100% !important;
}
.key_col2{
    max-width: 100% !important;
    margin-top: 30px !important;
    margin-left: 0px !important;
}
  
.key_col3{
    max-width: 100% !important;
    margin-top: 30px !important;
    margin-left: 0px !important;

}
  .dedication_second{
      display: none;
  }
.sincerity_div{
    background-size: 108%;
}

  }

